.hide-desktop-btn-dash{
    display: none;
  }
  .hide-desktop-arrow{
    display: none;
  }
  .hide-desktop{
    display: none ;
  }
  #consol_link{
    display: block;
  }
  #vis_link{
    display:none ;
  } 
  
  .show-hamburger-title{
      display:none;
  }
  @media screen and (max-width: 1200px) {
    .card-container {
       
        /* width: 20%; */
     
    }

  
  }
  
  @media screen and (max-width: 991px) {
      .hide-hamburger-title{
          display:none;
      }
      .show-hamburger-title{
        display:block;
    }
       .card-login {
        margin: 50px auto;
        background-color: #fff;
        width: 90%;
    }
     
    
      
   } 
  
   @media screen and (max-width: 850px) {
    .card-container{
        width: 50%;
      }
      
  }
  
  @media screen and (max-width: 767.98px) {
    .login-a{
        display:none;
    }
    .login-b{
        width: 50%;
        height: auto;
    
      }
      .card-login {
        margin: 0  auto;
        background-color: #fff;
        width: 90%;
    }

    .container-fluid-login{
        height: 100vh;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
    } 

    .login-b {
        width: 90%;
        height: auto;
  }  
  .logo-login-{
      display: block;
  }

  .LogoLogin-On-Mobile{
    width: 150px;
    height: 90px;
    display:block;
  }
  .Return-Sign-Content-movile {
   display: block;
    padding: 20px 0px 0px 20px;
}


}
  
  @media screen and (max-width: 576px) {
   

  
  }
  
  
  @media screen and (max-width: 430px) {

    .card-container{
        width: 100%;
      }
      
  
  }
  
  @media screen and (max-width: 330px) {

  }
  
  